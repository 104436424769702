import React, { useContext, lazy, Suspense } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// import ReactPlayer from 'react-player/lazy';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ListItem } from '../../../CustomStyles/Typography';
import FeatureThumbnailContext from '../../../../context/FeatureThumbnailContext';
import FormModalContext from '../../../../context/FormModalContext';

const Video = loadable(() => import('../../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		fontSize: '2.0243rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.8219rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5625rem',
		},
	},
	subHeader: {
		fontWeight: 400,
		color: theme.workwaveBlue,
		fontSize: '1.25rem',
	},
	printSubHeader: {
		fontWeight: 400,
		color: '#4B5B69',
		fontSize: '1.25rem',
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: '1px solid #d8dee0',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	img: {
		maxWidth: '600px',
	},
	button: {
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		fontSize: '1.25rem',
		paddingTop: '1em',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
	bulletCopy: {
		[theme.breakpoints.down('sm')]: {
			padding: '.5rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
	'@keyframes pulseBorder': {
		'0%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
			opacity: '1',
		},
		'100%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
			opacity: '0',
		},
	},
	playButton: {
		height: '100px',
		width: '100px',
		background: '#fff',
		color: '#002d5c',
		fontSize: '1.5rem',
		borderRadius: '50%',
		// position: 'absolute',
		// left: '100%',
		// marginTop: '215px',
		// marginLeft: '70px',
		// top: '44%',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		cursor: 'pointer',
		zIndex: 5,

		'&::before': {
			content: `''`,
			position: 'absolute',
			zIndex: '-1',
			left: '50%',
			top: '50%',
			transform: 'translateX(-50%) translateY(-50%)',
			display: 'block',
			width: '100px',
			height: '100px',
			background: '#fff',
			borderRadius: '50%',
		},

		'&:hover': {
			'& $playButtonEffect': { transform: 'scale(1.1)' },
			'&::before': {
				animation: '$pulseBorder 1500ms ease-out infinite',
			},
		},
		// [theme.breakpoints.down('lg')]: {
		// 	marginTop: '200px',
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	marginLeft: '50px',
		// 	marginTop: '140px',
		// },
	},
	playWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '500px',
		position: 'absolute',
		[theme.breakpoints.down(465)]: {
			height: '325px',
		},
		[theme.breakpoints.down(380)]: {
			height: '300px',
		},
	},
}));

export const BodyFeature = ({
	feature,
	accentColor,
	selectedFeature,
	featureRef,
	iosLogo,
	androidLogo,
	qbLogo,
	index,
	special,
	print,
}) => {
	const classes = useStyles();
	const {
		header,
		image,
		videoVariant,
		ctaLink,
		ctaText,
		// svgVariant,
		thumbnailVideoUrl,
		_rawFeatureContent,
		_key,
	} = feature;

	const { handleSelectThumbnail } = useContext(FeatureThumbnailContext);
	const { handleModalClick } = useContext(FormModalContext);

	const lg = useMediaQuery('(max-width: 1280px)');
	const xs = useMediaQuery('(max-width: 481px)');

	return (
		<Grid
			style={{ padding: lg ? '2rem 0 ' : '4rem 0' }}
			container
			ref={selectedFeature === _key ? featureRef : null}
			spacing={4}
			justifyContent='space-between'
			alignItems='center'
			direction='row'>
			<Grid item xs={12} md={6} container justifyContent='center'>
				{image ? (
					<>
						<GatsbyImage
							image={image.asset?.gatsbyImageData}
							alt='chart'
							className={classes.img}
							imgStyle={{ objectFit: 'contain', transition: '.5s' }}
						/>
						{thumbnailVideoUrl ? (
							<div className={classes.playWrapper}>
								<div
									className={classes.playButton}
									onClick={(e) => handleSelectThumbnail(e, thumbnailVideoUrl)}>
									<FontAwesomeIcon icon={['fas', 'play']} size='lg' />
								</div>
							</div>
						) : null}
					</>
				) : videoVariant ? (
					<div className={classes.playerWrapper}>
						<Video
							url={videoVariant}
							height='100%'
							width='100%'
							controls={true}
						/>
					</div>
				) : null}
			</Grid>
			<Grid
				container
				item
				xs={12}
				md={6}
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'>
				<Grid
					item
					container
					direction='column'
					alignItems='flex-start'
					justifyContent='space-evenly'>
					<PortableText
						content={_rawFeatureContent}
						// className={classes.text}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									style={{
										lineHeight: 0.9,
									}}>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Typography variant='body1' className={classes.text}>
									{children}
								</Typography>
							),
							li: ({ children }) => (
								<Grid item>
									<ListItem>
										<li className={classes.bulletCopy}>{children}</li>
									</ListItem>
								</Grid>
							),
						}}
					/>
				</Grid>

				<Grid container item>
					<Button
						variant='contained'
						size='large'
						color='primary'
						onClick={handleModalClick}
						className={classes.button}
						style={{
							color: 'white',
							marginLeft: '1rem',
							marginTop: '1rem',
							padding: '16px 32px',
						}}>
						{ctaText}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
