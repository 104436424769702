import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down('md')]: {
			marginTop: '2rem',
		},
	},
	heroImgBg: {
		position: 'absolute',
		maxWidth: '700px',
		right: '-100px',
		// right: "-5%",
		// top: "-80px",
		zIndex: 5,
		[theme.breakpoints.down('md')]: {
			// right: "-13%",
			// top: "-30px",
			maxWidth: '600px',
			right: '-150px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			// right: "0%",
			// top: "50px",
			maxWidth: '100%',
			right: '0',
		},
		[theme.breakpoints.down('xs')]: {
			// top: "30px",
			maxWidth: '100%',
			right: '0',
		},
	},

	heroImgMainCompare: {
		position: 'absolute',
		maxWidth: '700px',
		right: '4%',
		top: '-70px',
		zIndex: 5,

		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '30px',
			maxWidth: '100%',
			marginBottom: '-50px',
		},
	},
	heroImgAlternativeCompareBg: {
		position: 'absolute',
		maxWidth: '650px',
		right: '-7%',
		top: '-80px',
		zIndex: 5,
		[theme.breakpoints.down('md')]: {
			right: '-13%',
			top: '-30px',
			maxWidth: '600px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: '0%',
			top: '50px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '30px',
			maxWidth: '100%',
		},
	},
	heroImgBgSchedulingSoftware: {
		position: 'absolute',
		right: '-10%',
		bottom: '-80%',
		zIndex: 9999,
		[theme.breakpoints.down('md')]: {
			right: '-15%',
			bottom: '-60%',
			maxWidth: '530px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			bottom: '-50px',
			maxWidth: '608px',
		},
		[theme.breakpoints.down('xs')]: {
			bottom: '0',
			maxWidth: '100%',
		},
	},

	heroImgBgSupport: {
		position: 'absolute',
		right: '-93px',
		bottom: '-233px',
		zIndex: 9999,
		[theme.breakpoints.down('md')]: {
			right: '-85px',
			bottom: '-211px',
			maxWidth: '530px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			bottom: '-83px',
			maxWidth: '608px',
		},
		[theme.breakpoints.down('xs')]: {
			bottom: '0',
			maxWidth: '100%',
			marginTop: '50px',
		},
	},
	heroImgBgWhyPP: {
		position: 'absolute',
		maxWidth: '500px',
		right: '2%',
		top: '-60px',
		zIndex: 5,
		[theme.breakpoints.down('md')]: {
			right: '-6%',
			top: '-60px',
			maxWidth: '520px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: '0%',
			top: '50px',
			maxWidth: '475px',
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '30px',
			maxWidth: '100%',
		},
	},
}));

export const ImgRight = ({ heroImage, id }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');

	return (
		<Grid
			container
			item
			direction='column'
			className={classes.root}
			// justifyContent='flex-start'
			alignItems={md ? 'center' : 'flex-start'}
			xs={12}
			md={5}>
			<GatsbyImage
				image={heroImage}
				alt=''
				className={
					id === 'b720ff6c-323c-45e9-9d28-76e9904a29f5'
						? classes.heroImgBgSupport
						: id === 'c4f9a546-7741-482d-b5e5-1aa284a8b25d'
						? classes.heroImgBgSchedulingSoftware
						: id === 'b36f9bc3-11e5-4a87-8fe2-e01bcc367633'
						? classes.heroImgBgWhyPP
						: id === '18523616-32c5-4f45-bf10-336fa7d12833'
						? classes.heroImgMainCompare
						: id === 'f2055a6f-703a-40eb-8466-5c21f93f23e8' ||
						  id === 'b1b3e9ec-9b8b-4937-a024-fe54a9c8aef3'
						? classes.heroImgAlternativeCompareBg
						: classes.heroImgBg
				}
			/>
		</Grid>
	);
};
